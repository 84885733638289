@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Gotham Bold';
	src: url('../public/assets/fonts/GothamBold.ttf');
}
@font-face {
	font-family: 'Gotham XLight';
	src: url('../public/assets/fonts/Gotham-XLight.otf');
}
@font-face {
	font-family: 'Fira Sans Light';
	src: url('../public/assets/fonts/FiraSans-Light.ttf');
}

html,
body,
#root {
	width: 100vw;
	height: 100%;
	margin: 0;
	padding: 0;
	overflow: hidden;
}
